import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import classNames from 'classnames';
import React, {Component} from 'react';
import {Field, reduxForm, reducer as formReducer} from 'redux-form';
import {email as validateEmail} from 'goodeggs-validators';

import validatePhone from 'infra/validators/phone';
import Alert from 'web/components/alert';
import MarketLayout from 'web/components/market_layout';
import Panel from 'web/components/panel';

import AccountLayout from '../components/layout';
import {actions, reducer} from './duck';

const inputWithError = ({input, meta, label, className}) => (
  <div className={className}>
    <label>{label}</label>
    <input
      type="text"
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      className={classNames('form-control', {error: meta.error && !meta.active && meta.touched})}
    />
    {meta.error && !meta.active && meta.touched ? <div className="error">{meta.error}</div> : null}
  </div>
);

const AccountDetailsForm = ({pristine, submitting, handleSubmit}) => (
  <form className="account-details-page__form" onSubmit={handleSubmit}>
    <Field
      name="firstName"
      component={inputWithError}
      className="account-details-page__split-column"
      label="First Name"
    />
    <Field
      name="lastName"
      component={inputWithError}
      className="account-details-page__split-column"
      label="Last Name"
    />
    <Field
      name="email"
      component={inputWithError}
      className="account-details-page__row"
      label="Email"
    />
    <Field
      name="phone"
      component={inputWithError}
      className="account-details-page__row"
      label="Phone"
    />
    <div className="account-details__phone-disclaimer">
      By entering your phone number, you agree to receive text messages from Good Eggs regarding
      your order. Standard message rates apply. Only U.S. based numbers are allowed.
    </div>
    <button className="button" disabled={pristine || submitting} type="submit">
      Save
    </button>
  </form>
);

class AccountDetailsPage extends Component {
  handleSubmit(values) {
    this.props.dispatch(actions.save(values));
  }

  render() {
    return (
      <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
        <Helmet>
          <title>Account Details | Good Eggs</title>
        </Helmet>

        <div className="account-details-page">
          <AccountLayout slug="details">
            {this.props.globalError ? (
              <Alert
                className="account-details-page__alert"
                type="error"
                heading={this.props.globalError}
              />
            ) : null}
            <Panel header="Account Details">
              <AccountDetailsConnectedForm onSubmit={this.handleSubmit.bind(this)} />
            </Panel>
          </AccountLayout>
        </div>
      </MarketLayout>
    );
  }
}

function validateAccountDetails(values) {
  const errors = {};

  const fields = ['firstName', 'lastName', 'email', 'phone'];

  for (const field of fields) {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  }

  if (values.email && !validateEmail(values.email)) {
    errors.email = 'Invalid email';
  }

  if (values.phone && !validatePhone(values.phone)) {
    errors.phone = 'Invalid phone';
  }

  return errors;
}

const AccountDetailsReduxForm = reduxForm({
  form: 'accountDetails',
  validate: validateAccountDetails,
})(AccountDetailsForm);

const AccountDetailsConnectedForm = connect((state) => ({initialValues: state.user}))(
  AccountDetailsReduxForm,
);

AccountDetailsPage.reducer = function (state, action) {
  let newState = MarketLayout.reducer(state, action);
  newState = reducer(newState, action);
  const form = formReducer(newState.form, action);
  return {...newState, form};
};

AccountDetailsPage.pageName = 'Account Details';

export default connect((state) => state)(AccountDetailsPage);
