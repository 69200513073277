import {gql} from '@apollo/client';

import client from 'web/helpers/graphql_client';

export const ERROR_SAVING = 'ACCOUNT_DETAILS_ERROR_SAVING';

export const actions = {
  save:
    ({firstName, lastName, email, phone}) =>
    async (dispatch) => {
      try {
        const response = await updateUser({firstName, lastName, email, phone});

        if (response.errors) {
          throw response;
        }

        window.location = '/account?confirmChanges=true';
      } catch (error) {
        const message = error.message || 'Oops - something went wrong.';
        dispatch(actions.errorSaving(message));
      }
    },
  errorSaving: (message) => ({
    value: message,
    type: ERROR_SAVING,
  }),
};

export function reducer(state = {}, action = {}) {
  switch (action.type) {
    case ERROR_SAVING:
      return {...state, globalError: action.value};
    default:
      return state;
  }
}

export const updateUser = (input) => {
  return client.mutate({
    mutation: gql`
      mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
          user {
            firstName
            lastName
            email
            phone
          }
        }
      }
    `,
    variables: {
      input,
    },
  });
};
